import SlidingPanel from "components/SlidingPanel/index";
import useGridActions from "actions/grid";
import Grid from "components/Grid/index";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getColumnDefs } from "./columnDefs";
import { Row, Col, Card, Button } from "react-bootstrap";
import CustomModal from "components/Modal/Modal";
import SummaryBox from 'components/SummaryBox';
import { SummaryBoxTypes } from "enums/SummaryBoxTypes";
import {
  convertActivityHistoryEmailFilesToEmails,
  createPdfBundle,
  downloadActivityHistory,
  exportActivityHistory,
  getMatterActivityHistory,
  getMatterSummaryInfo,
  printActivityHistory,
  validateConvertActivityHistoryEmailFilesToEmails,
  validateCopyActivityHistory,
  validateMoveActivityHistory,
  validateShareActivityHistory
} from "actions/matter";
import Title from "components/Title/index";
import { MatterActivityHistoryTypes } from "enums/MatterActivityHistoryTypes";
import { GridIds } from "enums/GridIds";
import { GridOptionButtonDataModel } from "models/view/GridOptionButtonDataModel";
import { GridOptionTypes } from "enums/GridOptionTypes";
import { MatterActivityHistoryModel } from "models/view/MatterActivityHistoryModel";
import Loader from "components/Loader/index";
import useNotificationActions from "actions/notification";
import { NotificationTypes } from "enums/NotificationTypes";
import useSlidingPanelActions from 'actions/slidingPanel';
import ShareActivityHistoryForm from "./ShareActivityHistory/ShareActivityHistoryForm";
import store from "state/store";
import { useAppSelector } from "hooks/appSelector";
import { ICellRendererParams } from "ag-grid-community";
import RecordableEventDetailCellRenderer from "./RecordableEventDetailCellRenderer";
import FileDetailCellRenderer from "./FileDetailCellRenderer";
import CreateDraftEmail from "containers/Matter/CreateDraftEmail/CreateDraftEmail";
import InvoiceDetailCellRenderer from "./InvoiceDetailCellRenderer";
import { downloadFile, printPdf } from "utils/misc";
import "./style.scss";
import SummaryBoxesSection from "components/SummaryBoxesSection";
import { MatterSummaryInfoModel } from "models/view/MatterSummaryInfoModel";
import { MdRefresh } from "react-icons/md";
import { ModalState } from "state/modalSlice";
import useModalActions from "actions/modal";
import { getMatterTaxonomies } from "actions/taxonomy";
import BulkAddActivityHistoryTaxonomyTermsForm from "./ActivityHistoryTaxonomyTerms/BulkAddActivityHistoryTaxonomyTerms/BulkAddActivityHistoryTaxonomyTermsForm";
import MoveActivityHistoryForm from "./MoveActivityHistory/MoveActivityHistoryForm";
import CopyActivityHistoryForm from "./CopyActivityHistory/CopyActivityHistoryForm";
import BulkDeleteActivityHistoryTaxonomyTermsForm from "./ActivityHistoryTaxonomyTerms/BulkDeleteActivityHistoryTaxonomyTerms/BulkDeleteActivityHistoryTaxonomyTermsForm";
import { MatterInvoiceParticipatingEntitySummaryInfoModel } from "models/view/MatterInvoiceParticipatingEntitySummaryInfoModel";
import React from "react";
import { isMobile } from 'react-device-detect';

type Props = {
  matterId: string,
  matterFileNumber: string,
  syncMatterFiles: Function,
  matterIsClosed?: boolean
}

export default function ActivityHistoryGrid(props: Props) {
  const gridActions = useGridActions();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string | undefined>(undefined);
  const [matterSummaryInfo, setMatterSummaryInfo] = useState<MatterSummaryInfoModel | undefined>(undefined);
  const [isSummaryInfoLoading, setIsSummaryInfoLoading] = useState<boolean>(false);
  const [genericErrorsForSummaryInfo, setGenericErrorsForSummaryInfo] = useState(undefined);

  const notificationActions = useNotificationActions();
  const slidingPanelActions = useSlidingPanelActions();
  const loggedInUser = useAppSelector((state) => state.user);
  const slidingPanelState = useAppSelector((state) => state.slidingPanel);
  const modalActions = useModalActions();

  const isRowMaster = useMemo(() => {
    return (dataItem: any) => {
      return dataItem.activityType == MatterActivityHistoryTypes.RecordableEvent || 
        dataItem.activityType == MatterActivityHistoryTypes.File ||
        dataItem.activityType == MatterActivityHistoryTypes.Invoice;
    };
  }, []);

  const onGridReady = useCallback(() =>
  {
    loadMatterActivityHistory();
  }, [props.matterId]);

  useEffect(() => {
    loadMatterSummaryInfo();
  }, [props.matterId]);

  useEffect(() => {
    gridActions.setGridId(`${GridIds.ActivityHistory}/${props.matterId}`);
  }, []);

  const loadMatterActivityHistory = () => {
    getMatterTaxonomies(props.matterId).then((response) => {
      const columnDefs = getColumnDefs(props.matterId, response.data, props.syncMatterFiles);
      gridActions.setGridColumnDefs([...columnDefs]);
    })
    .catch((error) => {
      setGenericErrorsForSummaryInfo(error.response?.data?.Message ?? error.message);
    });

    if(props.matterId) {
      getMatterActivityHistory(props.matterId ?? '').then((response) => 
      {
        gridActions.setGridRowData(response.data);
      });
    }
    else {
      gridActions.setGridRowData([]);
    }
  }

  const loadMatterSummaryInfo = () => {
    if(props.matterId) {
      setIsSummaryInfoLoading(true);
      getMatterSummaryInfo(props.matterId).then((response) => {
        setMatterSummaryInfo(response.data);
      })
      .catch((error) => {
        setGenericErrorsForSummaryInfo(error.response?.data?.Message ?? error.message);
      })
      .finally(() => {
        setIsSummaryInfoLoading(false);
      });
    }
    else{
      setMatterSummaryInfo(undefined);
    }
  }

  const gridOptions: GridOptionButtonDataModel[] = [
    {
      type: GridOptionTypes.Download,
      callback: (rows: MatterActivityHistoryModel[]) => downloadRequiredFilesActivityHistory(rows)
    },
    ...(!props.matterIsClosed ? [ 
      {
        type: GridOptionTypes.Merge,
        callback: (rows: MatterActivityHistoryModel[]) => mergeFiles(rows)
      }
    ]: []),
    {
      type: GridOptionTypes.Print,
      callback: (rows: MatterActivityHistoryModel[]) => printFile(rows)
    },
    //Commented until moving it into an Azure function - task #1102
    //...(!props.matterIsClosed ? [
    //  {
    //    type: GridOptionTypes.ExportActivityHistory,
    //    callback: (rows: MatterActivityHistoryModel[]) => exportActivityHistoryAndUploadToSharepoint()
    //  },
    //] : []),
    {
      type: GridOptionTypes.Copy,
      callback: (rows: MatterActivityHistoryModel[]) => copyActivityHistoryItems(rows)
    },
    ...(!props.matterIsClosed ? [
      {
        type: GridOptionTypes.Move,
        callback: (rows: MatterActivityHistoryModel[]) => moveActivityHistoryItems(rows)
      }
    ]:[]),
    ...(!props.matterIsClosed ? [
      {
        type: GridOptionTypes.Share,
        callback: (rows: MatterActivityHistoryModel[]) => shareActivityHistoryItems(rows)
      }
    ] : []),
    {
      type: GridOptionTypes.SendFiles,
      callback: (rows: MatterActivityHistoryModel[]) => sendActivityHistoryItems(rows)
    },
    {
      type: GridOptionTypes.ConvertToMail,
      callback: (rows: MatterActivityHistoryModel[]) => convertToMails(rows)
    },
    {
      type: GridOptionTypes.BulkAddTaxonomyTerms,
      callback: (rows: MatterActivityHistoryModel[]) => bulkAddTaxonomyTermsToActivityHistoryItems(rows)
    },
    {
      type: GridOptionTypes.BulkDeleteTaxonomyTerms,
      callback: (rows: MatterActivityHistoryModel[]) => bulkDeleteTaxonomyTermsToActivityHistoryItems(rows)
    }
  ];

  const downloadRequiredFilesActivityHistory = (rows: MatterActivityHistoryModel[]) => {
    setIsLoading(true);
    setLoadingText("Downloading...");

    downloadActivityHistory(props.matterId, rows.map(x => x.id)).then((response) => {
      downloadFile(response);
    })
    .catch((error) => {
      error.response.data.text().then((text: any) => {
        let responseData = undefined;
        try {
          responseData = JSON.parse(text);
        }
        catch(e) {
          responseData = undefined;
        }
        notificationActions.addNotificationMessage(
          {
            type: NotificationTypes.Error,
            title: "Download Error",
            body: `${responseData?.Message ?? "There was an error while downloading the files."}`,
            isDismissable: true
          }
        );
      });
    })
    .finally(() => {
      setIsLoading(false);
      setLoadingText(undefined);
    });
  }

  const mergeFiles = (rows: MatterActivityHistoryModel[]) => {
    setIsLoading(true);
    setLoadingText("Merging...");

    createPdfBundle(rows[0].matterId, rows.map(x => x.id)).then((response) => {
      props.syncMatterFiles();
      notificationActions.addNotificationMessage(
      {
        type: NotificationTypes.Success,
        title: "Merge finished",
        body: "Upload to SharePoint has finished.",
        isDismissable: true
      });
    })
    .catch((error) => {
      error.response.data.text().then((text: any) => {
        let responseData = undefined;
        try {
          responseData = JSON.parse(text);
        }
        catch(e) {
          responseData = undefined;
        }
        notificationActions.addNotificationMessage(
          {
            type: NotificationTypes.Error,
            title: "Merge Error",
            body: `${responseData?.Message ?? "There was an error merging the files."}`,
            isDismissable: true
          }
        );
      });
    })
    .finally(() => {
      setIsLoading(false);
      setLoadingText(undefined);
    });
  }

  //Commented until moving it into an Azure function - task #1102
  /*const exportActivityHistoryAndUploadToSharepoint = () => {
    notificationActions.addNotificationMessage(
      {
        type: NotificationTypes.Success,
        title: "Export Started",
        body: "Exporting the Activity History started as a background task. You can continue using the app.",
        isDismissable: true
      });
    exportActivityHistory(props.matterId, true, loggedInUser.apiToken).then((response) => {
      if(!response.ok) {
        //turn ReadableStream into JSON 
        response.json().then((data: any) => {
          notificationActions.addNotificationMessage(
            {
              type: NotificationTypes.Error,
              title: `Export Error for Matter ${props.matterFileNumber}`,
              body: data.Message ?? "Exporting the Activity History failed.",
              isDismissable: true
            }
          );
        });
        return;
      }
      const gridState = store.getState().grid;
      if(gridState.id == `${GridIds.ActivityHistory}/${props.matterId}`) {
        props.syncMatterFiles();
      }
      notificationActions.addNotificationMessage(
      {
        type: NotificationTypes.Success,
        title: `Export Finished for Matter ${props.matterFileNumber}`,
        body: `Exporting the Activity History finished for Matter ${props.matterFileNumber}.`,
        isDismissable: true
      });
    })
    .catch((error) => {
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Error,
          title: `Export Error for Matter ${props.matterFileNumber}`,
          body: "Exporting the Activity History failed.",
          isDismissable: true
        }
      );
    });
  }*/

  const printFile = (rows: MatterActivityHistoryModel[]) => {
    setIsLoading(true);
    setLoadingText("Preparing printing document...");

    printActivityHistory(rows[0].matterId, rows.map(x => x.id)).then((response) => {
      printPdf(response.data);
    })
    .catch((error) => {
      //the response is Blob here, we need to parse it
      error.response.data.text().then((text: any) => {
        let responseData = undefined;
        try {
          responseData = JSON.parse(text);
        }
        catch(e) {
          responseData = undefined;
        }
        notificationActions.addNotificationMessage(
          {
            type: NotificationTypes.Error,
            title: "Print Error",
            body: `${responseData?.Message ?? "There was an error printing the file."}`,
            isDismissable: true
          }
        );
      });
    })
    .finally(() => {
      setIsLoading(false);
      setLoadingText(undefined);
    });
  }

  const copyActivityHistoryItems = (rows: MatterActivityHistoryModel[]) => {
    setIsLoading(true);

    validateCopyActivityHistory(props.matterId, rows.map(x => x.id)).then((response) => {
      slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Copy Activity History Items",
          children: <CopyActivityHistoryForm matterId={props.matterId} activityHistoryRecords={rows} syncMatterFiles={props.syncMatterFiles}/>
        });
    })
    .catch((error) => {
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Error,
          title: "Copy Validation Error",
          body: error.response?.data?.Message ?? error.message,
          isDismissable: true
        }
      );
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const moveActivityHistoryItems = (rows: MatterActivityHistoryModel[]) => {
    setIsLoading(true);
    const shouldDisplayClient: boolean = rows.some(x => x.activityType == MatterActivityHistoryTypes.RecordableEvent || 
      x.activityType == MatterActivityHistoryTypes.Disbursement || x.activityType == MatterActivityHistoryTypes.IncidentalExpense);

    validateMoveActivityHistory(props.matterId, rows.map(x => x.id)).then((response) => {
      slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Move Activity History Items",
          children: <MoveActivityHistoryForm 
            matterId={props.matterId}
            activityHistoryRecords={rows}
            displayClient={shouldDisplayClient}
            syncMatterFiles={props.syncMatterFiles}
          />
        });
    })
    .catch((error) => {
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Error,
          title: "Move Validation Error",
          body: error.response?.data?.Message ?? error.message,
          isDismissable: true
        }
      );
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const convertToMails = (rows: MatterActivityHistoryModel[]) => {
    setIsLoading(true);
    setLoadingText("Validating...");

    validateConvertActivityHistoryEmailFilesToEmails(props.matterId, rows.map(x => x.id)).then((response) => {
      let modal: ModalState = {
        title: "Covert confirmation",
        body: "The selected files will be renamed and moved in Emails folder on SharePoint. Are you sure you want to convert them?",
        actionText: "Confirm",
        actionVariant: "success",
        onAction: () => {
          modalActions.toggleModalShownStatus();
          setIsLoading(true);
          setLoadingText("Converting...");
          convertActivityHistoryEmailFilesToEmails(props.matterId, rows.map(x => x.id)).then((response2) => {
            
          })
          .catch((error) => {
            notificationActions.addNotificationMessage(
              {
                type: NotificationTypes.Error,
                title: "Converting to Mail Error",
                body: `${error.response?.data?.Message ?? error.message ?? "There was an error while converting the files to emails."}`,
                isDismissable: true
              }
            );
          })
          .finally(() => {
            loadMatterActivityHistory();
            setIsLoading(false);
            setLoadingText(undefined);
          });
        },
        show: true
      }

      setIsLoading(false);
      setLoadingText(undefined);
      modalActions.setModal(modal);
    })
    .catch((error) => {
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Error,
          title: "Converting to Mail Validation Error",
          body: error.response?.data?.Message ?? error.message,
          isDismissable: true
        }
      );
      setIsLoading(false);
      setLoadingText(undefined);
    });
  }

  const shareActivityHistoryItems = (rows: MatterActivityHistoryModel[]) => {
    setIsLoading(true);

    validateShareActivityHistory(props.matterId, rows.map(x => x.id)).then((response) => {
      slidingPanelActions.setSlidingPanel(
        {
          isShown: true,
          title: "Share Files",
          children: <ShareActivityHistoryForm matterId={props.matterId} activityHistoryRecords={rows}/>
        });
    })
    .catch((error) => {
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Error,
          title: "Share Files Validation Error",
          body: error.response?.data?.Message ?? error.message,
          isDismissable: true
        }
      );
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const sendActivityHistoryItems = (rows: MatterActivityHistoryModel[]) => {
    if(rows.some(x => x.activityType != MatterActivityHistoryTypes.File))
    {
      notificationActions.addNotificationMessage(
        {
          type: NotificationTypes.Error,
          title: "Send Files Error",
          body: "Some of the selected items are not files.",
          isDismissable: true
        }
      );
    }
    else {
      slidingPanelActions.setSlidingPanel(
        {
          width: "50rem",
          isShown: !slidingPanelState.isShown,
          title: "Add Draft Email",
          children: <CreateDraftEmail 
            matterId={props.matterId}
            matterFileNumber={props.matterFileNumber}
            attachmentsIds={rows.filter(x => x.activityType == MatterActivityHistoryTypes.File && x.matterFileId).map(x => x.matterFileId!)}
          />
        }
      );
    }
  }

  const bulkAddTaxonomyTermsToActivityHistoryItems = (rows: MatterActivityHistoryModel[]) => {
    if(rows.some(x => x.taxonomyTerms != undefined && x.taxonomyTerms.length > 0))
    {
      let modal: ModalState = {
        title: "Bulk add taxonomy terms confirmation",
        body: "At least one of the selected records already has some taxonomy terms and they may be overwritten. Are you sure you want to continue?",
        actionText: "Confirm",
        onAction: () => {
          modalActions.toggleModalShownStatus();
            slidingPanelActions.setSlidingPanel(
              {
                isShown: !slidingPanelState.isShown,
                title: "Bulk Add Taxonomy Terms",
                children: <BulkAddActivityHistoryTaxonomyTermsForm
                    matterId={props.matterId}
                    activityHistoryRecords={rows}
                  />
              }
            );
        },
        show: true
      }
      modalActions.setModal(modal);
    }
    else {
      slidingPanelActions.setSlidingPanel(
        {
          isShown: !slidingPanelState.isShown,
          title: "Bulk Add Taxonomy Terms",
          children: <BulkAddActivityHistoryTaxonomyTermsForm
              matterId={props.matterId}
              activityHistoryRecords={rows}
            />
        }
      );
    }
  }

  const bulkDeleteTaxonomyTermsToActivityHistoryItems = (rows: MatterActivityHistoryModel[]) => {
    slidingPanelActions.setSlidingPanel(
      {
        isShown: !slidingPanelState.isShown,
        title: "Bulk Delete Taxonomy Terms",
        children: <BulkDeleteActivityHistoryTaxonomyTermsForm
            matterId={props.matterId}
            activityHistoryRecords={rows}
          />
      }
    );
  }

  const getDetailCellRenderer = (matterId: string) => {
    return (parameters: ICellRendererParams) => {
      switch (parameters.data.activityType) {
        case MatterActivityHistoryTypes.RecordableEvent: {
          return <RecordableEventDetailCellRenderer matterId={matterId} rendererParams={parameters} />;
        }
        case MatterActivityHistoryTypes.File: {
          return <FileDetailCellRenderer matterId={matterId} rendererParams={parameters}/>;
        }
        case MatterActivityHistoryTypes.Invoice: {
          return <InvoiceDetailCellRenderer matterId={matterId} rendererParams={parameters}/>;
        }
        default:
          return <></>;
      }
    };
  }

  return (
    <>
      {isSummaryInfoLoading &&
        <div className="lp-summary-boxes-placeholder">
          <Loader inlineLoader />
        </div>
      }

      {genericErrorsForSummaryInfo && (
        <div className="lp-errors">
          {genericErrorsForSummaryInfo}
        </div>
      )}

      {matterSummaryInfo && 
        <SummaryBoxesSection>
          <div className="lp-summary-boxes-area">
            <SummaryBox
              type={SummaryBoxTypes.TimeDoughnut}
              values={[
                {
                  value: matterSummaryInfo.totalBilledMinutesRecordableItems ?? 0,
                  label: "Billed",
                  className: "lp-primary"
                },
                {
                  value: matterSummaryInfo.totalUnbilledMinutesRecordableItems ?? 0,
                  label: "Unbilled",
                  className: "lp-warning"
                }
              ]}
            />

            <SummaryBox 
              type={SummaryBoxTypes.FeeIncomeDoughnut}
              values={[
                {
                  value: matterSummaryInfo.totalBilledRecordableItems ?? 0,
                  label: "Billed",
                  className: "lp-primary"
                },
                {
                  value: matterSummaryInfo.totalUnbilledRecordableItems ?? 0,
                  label: "Unbilled",
                  className: "lp-warning"
                }
              ]}
            />

            <SummaryBox
              type={SummaryBoxTypes.DisbursementsDoughnut}
              values={[
                {
                  value: matterSummaryInfo.totalBilledDisbursements ?? 0,
                  label: "Billed",
                  className: "lp-primary",
                  
                },
                {
                  value: matterSummaryInfo.totalUnbilledDisbursements ?? 0,
                  label: "Unbilled",
                  className: "lp-warning"
                },
                ...matterSummaryInfo.totalWriteOffDisbursements ? [{
                  value: matterSummaryInfo.totalWriteOffDisbursements ?? 0,
                  label: "Write-Off",
                  className: "lp-danger"
                }] : []
              ]}
            />

            <SummaryBox
              type={SummaryBoxTypes.IncidentalExpensesDoughnut}
              values={[
                {
                  value: matterSummaryInfo.totalBilledIncidentalExpenses ?? 0,
                  label: "Billed",
                  className: "lp-primary"
                },
                {
                  value: matterSummaryInfo.totalUnbilledIncidentalExpenses ?? 0,
                  label: "Unbilled",
                  className: "lp-warning"
                },
                ...matterSummaryInfo.totalWriteOffIncidentalExpenses ? [{
                  value: matterSummaryInfo.totalWriteOffIncidentalExpenses ?? 0,
                  label: "Write-Off",
                  className: "lp-danger"
                }] : []
              ]}
            />

            <SummaryBox
              type={SummaryBoxTypes.InvoicesDoughnut}
              values={[
                {
                  value: matterSummaryInfo.totalPaidInvoices ?? 0,
                  label: "Paid",
                  className: "lp-primary"
                },
                {
                  value: matterSummaryInfo.totalUnpaidInvoices ?? 0,
                  label: "Unpaid",
                  className: "lp-warning"
                },
                ...matterSummaryInfo.totalWriteOffInvoices ? [{
                  value: matterSummaryInfo.totalWriteOffInvoices ?? 0,
                  label: "Write-Off",
                  className: "lp-danger"
                }] : []
              ]}
            />

            {matterSummaryInfo?.invoiceParticipatingEntitySummaryInfos &&
              matterSummaryInfo.invoiceParticipatingEntitySummaryInfos.map((x: MatterInvoiceParticipatingEntitySummaryInfoModel, index: number) => (
                <React.Fragment key={index}>
                  <SummaryBox
                    type={SummaryBoxTypes.InvoiceParticipatingEntityDoughnut}
                    title={x.matterInvoiceDisplayName + ' - ' + x.matterParticipatingEntity?.name + ' - ' + x.matterInvoiceStatus?.name}
                    values={[
                      {
                        value: x.totalRecordableItems ?? 0,
                        label: isMobile ? "Fee" : "Professional Fees",
                        className: "lp-primary"
                      },
                      {
                        value: x.totalDisbursements ?? 0,
                        label: isMobile ? "Dis" : "Disbursements",
                        className: "lp-primary-400"
                      },
                      {
                        value: x.totalIncidentalExpenses ?? 0,
                        label: isMobile ? "Exp" : "Incidental Expenses",
                        className: "lp-primary-700"
                      }
                    ]}
                  />
                </React.Fragment>
              ))
            }
          </div>
        </SummaryBoxesSection>
      }
      
      {isLoading && <Loader text={loadingText} />}

      <Row>
        <Col>
          <Title
            type="section"
            title={<>Activity History</>}
          >
            <Button onClick={loadMatterActivityHistory} className="btn-icon" variant="primary">
              <MdRefresh />
            </Button>
          </Title>
          <Card className="with-grid">
            <Card.Body>
              <Grid 
                onGridReady={onGridReady}
                isRowMaster={isRowMaster}
                masterDetail={true}
                gridOptions={gridOptions}
                detailCellRenderer={getDetailCellRenderer(props.matterId)}
                pagination={true}
                paginationPageSize={25}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <SlidingPanel/>

      <CustomModal />
      <iframe className={"lp-print-helper-hidden"} />
    </>
  );
}
